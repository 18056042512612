






























































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { LibraryManagementService } from '@/services/library-management-service';

@Component({
  components: {}
})
export default class documentManagement extends Vue {
  public form = {}
  public tableData: any[] = [];
  public loading: Boolean = true;
  private linkPre: string = '';
  public articleContentVisible: boolean = false;

  @Inject(LibraryManagementService) private libraryManagementService!: LibraryManagementService;

  public async created(): Promise<void> {
  this.form =  {
                  pages: 1,
                  size: 10,
                  current: 1,
                  total: 0,
                }
    await this.getList();
  }
  //预览
  public async preview(row: any) {
    const res = await this.libraryManagementService.queryArticleInfo(row.id);
    if(res!= null){
      this.linkPre = res.articleContent;
      this.articleContentVisible = true;
      this.$nextTick(() => {
        const imgElements = (this.$refs.linkPre as HTMLElement).querySelectorAll('img');
        imgElements.forEach((imgElement) => {
          imgElement.style.maxWidth = '100%';
        });
      });
    }
  }
  public close() {
    this.articleContentVisible = false;
    const videoElement = document.querySelector('video');
    if(videoElement) videoElement.pause();
  }
  //查询接口
  public search() {
    this.form.current = 1;
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.size = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.current = current;
    this.getList();
  }
  //列表赋值
  public formData(res: any) {
    let data = res;
    this.tableData = data;
  }
  //获取数据接口
  public async getList() {
    this.loading = true;
    const res = await this.libraryManagementService.queryArticleList(this.form || {});
    console.log(res)
    this.loading = false;
    //this.formData()
    this.tableData = res.records;
    this.form.total = res.total || 0;
    this.form.pages = res.pages  || 0;
    this.form.size = res.size  || 10;
    this.form.current = res.current   || 1;
  }
  //删除接口
  private async handDelete(row: any): Promise<void> {
    let param = {
      id: row.id
    }
    try {
      await this.$confirm('删除后，该文章将不会再进行展示，请确认是否删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      await this.libraryManagementService.queryArticleDelete(param);
      this.getList()
      this.$message({
        message: '删除成功',
        type: 'success'
      });
    } catch (e) {
      this.$message({
        type: 'info',
        message: '已取消删除'
      });
    }
  }
  //修改接口
  public handUpdate(row: any) {
    localStorage.setItem('addDocument', row.id)
    this.$router.push("/addDocument");
  }
  //新增接口
  public createDocument() {
    localStorage.setItem('addDocument', '-1')
    this.$router.push("/addDocument");
  }
  //重置
  public reset() {
    this.form = {
      appOrScenarioName: '',
      secondMenuName: '',
      articleName: ''
    };
    this.getList()
  }
  private mounted() {
    document.onkeydown = (e) => {
      let key = (window.event as any).keyCode;
      if (key == 13) {
        this.search()
      }
    }
  }
}
